<template>
  <v-layout align-center>
    <v-autocomplete
      :search-input.sync="search"
      :items="categories"
      :value="value"
      @input="changed"
      :label="label"
      :loading="isLoading"
      :rules="rules"
      item-text="name"
      return-object
      multiple
      chips
      clearable
      :hide-no-data="searched"
    ></v-autocomplete>
    <v-btn @click="dialog = true" icon small color="secondary">
      <v-icon>add_circle</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" v-if="dialog" :width="600">
      <form-categories @create="created"></form-categories>
    </v-dialog>
  </v-layout>
</template>

<script>
import { findByName } from "@/services/categories-service";
import { mapGetters } from "vuex";

export default {
  components: {
    "form-categories": () => import("./Form"),
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    categories: [],
    dialog: false,
    search: "",
  }),
  computed: {
    ...mapGetters("loading", ["isLoading"]),
    searched() {
      return this.search && this.search.length === 0;
    },
  },
  created() {
    this.loadValueToItems(this.value);
  },
  watch: {
    search(value) {
      if (value && value.length > 0) {
        this.list();
      }
    },
    value(value) {
      this.loadValueToItems(value);
    },
  },
  methods: {
    loadValueToItems(value) {
      if (value && value.length > 0) {
        this.categories = value;
      }
    },
    async list() {
      try {
        let { data } = await findByName(this.search);
        this.categories = [...data, ...this.value];
      } catch (error) {
        this.$toasted.global.defaultError();
      }
    },
    created(createdCategory) {
      this.categories.push(createdCategory);
      this.value.push(createdCategory);
      this.$emit("input", this.value);
      this.dialog = false;
    },
    changed(value) {
      this.search = "";
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped></style>
